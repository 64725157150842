var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"sidebar sidebarTag"},[_c('k-nav',{attrs:{"vertical":""},on:{"click":_vm.changeNotice}},[_vm._l((_vm.navItems),function(item){return [(!item.childMenu && item.menu.useForWeb)?_c('k-nav-item',{key:`web${item.menu.id}`,staticClass:"wapHide",attrs:{"to":{ name: item.menu.route },"exact-active-class":"active"}},[(item.menu.displayIcons)?_c('img',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip",value:({
            placement: 'topright',
            trigger: 'hover',
            title: item.menu.menuName,
            boundary: 'window',
          }),expression:"{\n            placement: 'topright',\n            trigger: 'hover',\n            title: item.menu.menuName,\n            boundary: 'window',\n          }"}],staticStyle:{"width":"16px","height":"16px"},attrs:{"src":item.menu.menuIcon,"alt":""}}):_vm._e(),_c('span',{staticClass:"navTitle"},[_vm._v(" "+_vm._s(item.menu.menuName)+" "),(item.menu.route == 'messages')?_c('b-badge',{staticClass:"featuredMessages",attrs:{"variant":"featured"}},[_vm._v(" "+_vm._s(_vm.getMessageCount)+" ")]):_vm._e()],1)]):_vm._e(),(!item.childMenu && item.menu.useForWap&&item.menu.id!='9c711272-6b97-4d72-b234-92ddf672f353')?_c('k-nav-item',{key:`wap${item.menu.id}`,staticClass:"wapShow",attrs:{"exact-active-class":"active","to":{ name:item.menu.route }}},[(item.menu.displayIcons)?_c('img',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip",value:({
            placement: 'topright',
            trigger: 'hover',
            title: item.menu.menuName,
            boundary: 'window',
          }),expression:"{\n            placement: 'topright',\n            trigger: 'hover',\n            title: item.menu.menuName,\n            boundary: 'window',\n          }"}],staticStyle:{"width":"16px","height":"16px"},attrs:{"src":item.menu.menuIcon,"alt":""}}):_vm._e(),_c('span',{staticClass:"navTitle"},[_vm._v(" "+_vm._s(item.menu.menuName)+" ")])]):_vm._e(),(!item.childMenu && item.menu.useForWap&&item.menu.id=='9c711272-6b97-4d72-b234-92ddf672f353')?_c('k-nav-item',{key:`wap${item.menu.id}`,staticClass:"wapShow",attrs:{"exact-active-class":"active"},on:{"click":function($event){return _vm.onLogout()}}},[(item.menu.displayIcons)?_c('img',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip",value:({
            placement: 'topright',
            trigger: 'hover',
            title: item.menu.menuName,
            boundary: 'window',
          }),expression:"{\n            placement: 'topright',\n            trigger: 'hover',\n            title: item.menu.menuName,\n            boundary: 'window',\n          }"}],staticStyle:{"width":"16px","height":"16px"},attrs:{"src":item.menu.menuIcon,"alt":""}}):_vm._e(),_c('span',{staticClass:"navTitle"},[_vm._v(" "+_vm._s(item.menu.menuName)+" ")])]):_vm._e(),(item.childMenu)?_c('k-nav-item',{directives:[{name:"b-toggle",rawName:"v-b-toggle",value:(item.menu.id),expression:"item.menu.id"}],key:item.menu.menuName,attrs:{"link-classes":"space-between"}},[_c('span',[(item.menu.displayIcons)?_c('img',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip",value:({
              placement: 'topright',
              trigger: 'hover',
              title: item.menu.menuName,
              boundary: 'window',
            }),expression:"{\n              placement: 'topright',\n              trigger: 'hover',\n              title: item.menu.menuName,\n              boundary: 'window',\n            }"}],staticStyle:{"width":"16px","height":"16px"},attrs:{"src":item.menu.menuIcon,"alt":""}}):_vm._e(),_c('span',{staticClass:"navTitle"},[_vm._v(" "+_vm._s(item.menu.menuName)+" ")])]),_c('span',{staticClass:"navTitle"},[_c('b-icon',{attrs:{"icon":"chevron-down"}}),_c('b-icon',{attrs:{"icon":"chevron-up"}})],1)]):_vm._e(),(item.childMenu)?_c('k-collapse',{key:item.menu.id,staticClass:"nav-child",attrs:{"id":item.menu.id},model:{value:(_vm.navToggle[item.menu.id]),callback:function ($$v) {_vm.$set(_vm.navToggle, item.menu.id, $$v)},expression:"navToggle[item.menu.id]"}},_vm._l((item.childMenu),function(child){return _c('k-nav-item',{key:child.menuName,attrs:{"to":{ name: child.route },"exact-active-class":"active","link-classes":"pl-4"}},[(item.menu.displayIcons)?_c('img',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip",value:({
              placement: 'topright',
              trigger: 'hover',
              title: child.menuName,
              boundary: 'window',
            }),expression:"{\n              placement: 'topright',\n              trigger: 'hover',\n              title: child.menuName,\n              boundary: 'window',\n            }"}],staticStyle:{"width":"16px","height":"16px"},attrs:{"src":child.menuIcon,"alt":""}}):_vm._e(),_c('span',{staticClass:"navTitle"},[_vm._v(" "+_vm._s(child.menuName)+" ")])])}),1):_vm._e()]})],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }