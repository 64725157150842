<template>
  <div class="layoutPage" v-if="isOpen">
    <HeadInfo v-if="!is404" :listActiveNum="isListActiveNum" />
    <div class="portalMain">
      <Nav ref="navSidebar" v-if="!is404" />
      <div class="portalContent">
        <Breadcrumb v-if="!is404" />
        <router-view ref="content" v-if="isRouterAlive" />
      </div>
    </div>
    <FooterInfo />
  </div>
</template>

<script>
import Nav from "./components/nav";
import Breadcrumb from "./components/breadcrumb";
import { eventBus, storeage } from "fe-infrastractures";
import { portalSettingMixin } from "@/utils/mixin/portalSettingMixin";
import HeadInfo from "@/layout/headInfo";
import FooterInfo from "@/layout/footerInfo";

export default {
  mixins: [portalSettingMixin],
  data() {
    return {
      isRouterAlive: true,
      isRouterAliveNum: 0,
      is404: this.$route.meta.is404,
      isOpen: true,
      isListActiveNum: 0,
    };
  },
  components: {
    Nav,
    Breadcrumb,
    HeadInfo,
    FooterInfo,
  },
  computed: {
    isCollapse() {
      return this.$store.getters.isCollapse;
    },
  },
  watch: {
    isRouterAliveNum(val) {
      this.isRouterAlive = false;
      this.$nextTick(() => {
        this.isRouterAlive = true;
      });
    },
  },
  mounted() {
    eventBus.$on("clickNav", (routeName) => {
      if (this.$route.name === routeName || this.$route.redirectedFrom) {
        this.isRouterAliveNum++;
      }
    });
    storeage.removeElectionId();
    eventBus.$on("clickWapNav", () => {
      this.isListActiveNum++;
    });
  },
};
</script>
