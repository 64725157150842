<template>
  <div class="sidebar sidebarTag">
    <k-nav
      vertical
      @click="changeNotice"
    >
      <template v-for="item in navItems">
        <k-nav-item
          v-if="!item.childMenu && item.menu.useForWeb"
          :to="{ name: item.menu.route }"
          exact-active-class="active"
          :key="`web${item.menu.id}`"
          class="wapHide"
        >
          <img
            :src="item.menu.menuIcon"
            style="width: 16px; height: 16px"
            v-if="item.menu.displayIcons"
            v-b-tooltip="{
              placement: 'topright',
              trigger: 'hover',
              title: item.menu.menuName,
              boundary: 'window',
            }"
            alt=""
          >
          <span class="navTitle">
            {{ item.menu.menuName }}
            <b-badge
              variant="featured"
              class="featuredMessages"
              v-if="item.menu.route == 'messages'"
            >
              {{ getMessageCount }}
            </b-badge>
          </span>
        </k-nav-item>
        <k-nav-item
          v-if="!item.childMenu && item.menu.useForWap&&item.menu.id!='9c711272-6b97-4d72-b234-92ddf672f353'"
          exact-active-class="active"
          :to="{ name:item.menu.route }"
          :key="`wap${item.menu.id}`"
          class="wapShow"
        >
          <img
            :src="item.menu.menuIcon"
            style="width: 16px; height: 16px"
            v-if="item.menu.displayIcons"
            v-b-tooltip="{
              placement: 'topright',
              trigger: 'hover',
              title: item.menu.menuName,
              boundary: 'window',
            }"
            alt=""
          >
          <span class="navTitle">
            {{ item.menu.menuName }}
          </span>
        </k-nav-item>

        <k-nav-item
          v-if="!item.childMenu && item.menu.useForWap&&item.menu.id=='9c711272-6b97-4d72-b234-92ddf672f353'"
          exact-active-class="active"
          :key="`wap${item.menu.id}`"
          class="wapShow"
          @click="onLogout()"
        >
          <img
            :src="item.menu.menuIcon"
            style="width: 16px; height: 16px"
            v-if="item.menu.displayIcons"
            v-b-tooltip="{
              placement: 'topright',
              trigger: 'hover',
              title: item.menu.menuName,
              boundary: 'window',
            }"
            alt=""
          >
          <span class="navTitle">
            {{ item.menu.menuName }}
          </span>
        </k-nav-item>

        <k-nav-item
          v-if="item.childMenu"
          :key="item.menu.menuName"
          v-b-toggle="item.menu.id"
          link-classes="space-between"
        >
          <span>
            <img
              :src="item.menu.menuIcon"
              style="width: 16px; height: 16px"
              v-if="item.menu.displayIcons"
              v-b-tooltip="{
                placement: 'topright',
                trigger: 'hover',
                title: item.menu.menuName,
                boundary: 'window',
              }"
              alt=""
            >
            <span class="navTitle">
              {{ item.menu.menuName }}
            </span>
          </span>
          <span class="navTitle">
            <b-icon icon="chevron-down" />
            <b-icon icon="chevron-up" />
          </span>
        </k-nav-item>
        <k-collapse
          v-if="item.childMenu"
          :key="item.menu.id"
          :id="item.menu.id"
          class="nav-child"
          v-model="navToggle[item.menu.id]"
        >
          <k-nav-item
            v-for="child in item.childMenu"
            :key="child.menuName"
            :to="{ name: child.route }"
            exact-active-class="active"
            link-classes="pl-4"
          >
            <img
              :src="child.menuIcon"
              style="width: 16px; height: 16px"
              v-if="item.menu.displayIcons"
              v-b-tooltip="{
                placement: 'topright',
                trigger: 'hover',
                title: child.menuName,
                boundary: 'window',
              }"
              alt=""
            >
            <span class="navTitle">
              {{ child.menuName }}
            </span>
          </k-nav-item>
        </k-collapse>
      </template>
    </k-nav>
  </div>
</template>
<script>
import { eventBus, logout } from "fe-infrastractures";
import { doubleClickMenuKey } from "@/utils/storeageKey";
import { PL_NAV_KEY } from "@/router/permissionCode";

export default {
  props: {
    navAry: {
      type: Array,
      default(){
        return [];
      },
    },
    navId: {
      type: String,
      default: "",
    },
  },

  data(){
    let navs = this.$store.getters.navToggle[this.navId];
    let navToggle = {};

    if(navs){
      for (let i in navs) {
        navToggle[i] = navs[i];
      }
    }

    return {
      navToggle,
      isInit: true,
    };
  },
  methods: {
    getDefaultFirstMenuName(navkey){
      let navs = this.$store.getters.nav[navkey] || [];
      if(navs.length){
        let firstNav = navs[0];
        if(firstNav.childMenu){
          return firstNav.childMenu[0]?.menuName;
        } else {
          return firstNav.menu?.menuName;
        }
      } else {
        return "";
      }
    },
    changeNotice(event){
      if(
          (event.target?.localName === "a" &&
              event.target?.className.indexOf("space-between") === - 1) ||
          (event.target?.parentElement?.localName === "a" &&
              event.target?.parentElement?.className.indexOf("space-between") ===
              - 1)
      ){
        let preName = sessionStorage.getItem(doubleClickMenuKey);
        this.changeNoticeFunc(preName, event);
        sessionStorage.setItem(doubleClickMenuKey, event.target.innerText);
        this.wapSidebar();
      }
    },
    changeNoticeFunc(preName, event){
      if(preName){
        if(preName?.trim() === event.target.innerText?.trim()){
          let routeName = this.getCurrentRoute(preName?.trim());
          eventBus.$emit("clickNav", routeName);
        }
      } else {
        let menuName = this.getDefaultFirstMenuName(PL_NAV_KEY);
        if(menuName.trim() === event.target.innerText?.trim()){
          let routeName = this.getCurrentRoute(menuName.trim());
          eventBus.$emit("clickNav", routeName);
        }
      }
    },
    getCurrentRoute(menuName){
      let route = "";
      this.navItems.forEach((item) => {
        if(item.menu.menuName?.trim() === menuName){
          route = item.menu.route;
        } else {
          if(item.childMenu){
            let menu = item.childMenu.find(
                (item) => item.menuName?.trim() === menuName,
            );
            if(menu){
              route = menu.route;
            }
          }
        }
      });
      return route;
    },
    onLogout(){
      this.$confirm({
        title: this.$g("const.confirm.confirm"),
        content: this.$g("const.confirm.logOutComfirm"),
        cancelType: "secondary",
        okText: this.$g("const.confirm.yes"),
        cancelText: this.$g("const.confirm.no"),
      }).then(() => {
        logout();
        this.$router.push({ name: "login" });
      });
    },
    wapSidebar(){
      let siderbar = document.getElementsByClassName("sidebarTag")[0];
      if(siderbar){
        document.body.style.overflow = "";
        siderbar.classList.remove("open");
        eventBus.$emit("clickWapNav");
      }
    },
  },
  computed: {
    navItems(){
      return this.navAry.length > 0
          ? this.navAry
          : this.$store.getters.nav[this.navId] || [];
    },
    getMessageCount(){
      return this.$store.state.app.portalMessageCount || 0;
    },
  },
  created(){
    this.initRoute = this.$route.name;
  },
  mounted(){
    let storeNavToggle = this.$store.getters.navToggle[this.navId];
    let navs = this.$store.getters.nav[this.navId] || [];
    let firstNav = navs.length ? navs[0] : "";
    for (const key in storeNavToggle) {
      if(
          storeNavToggle.hasOwnProperty.call(storeNavToggle, key) &&
          storeNavToggle[key]
      ){
        this.navToggle[key] = true;
      }
    }
    if(firstNav && firstNav.childMenu){
      this.navToggle[firstNav.menu?.id] = true;
    }
    this.$nextTick(() => {
      this.isInit = false;
    });
  },
  watch: {
    navToggle: {
      handler(newVal, oldVal){
        if(!this.isInit){
          this.$store.dispatch("nav/saveNavToggle", {
            navToggleId: this.navId,
            condition: { ...newVal },
          });
        }
      },
      deep: true,
    },
    seenSidebar(val){
      this.$store.dispatch("menu/saveNavToggle", {
        type: "seenSidebar",
        value: val,
      });
    },
  },
};
</script>
<style lang="scss" scoped>
::v-deep {
  .wapShow {
    > a {
      border-bottom: 1px solid #ccc;
    }
  }

  .featuredMessages {
    background: var(--featuredFont);
    color: #fff;
    padding: 3px 5px;
    border-radius: 6px;
    float: right;
    font-size: inherit;
  }
}
</style>
