<template>
  <k-breadcrumb>
    <b-breadcrumb-item class="homeIcon">
      <b-icon icon="house-fill" />
      <span style="display: none">wcag</span>
    </b-breadcrumb-item>
    <b-breadcrumb-item
      v-for="item in routeName"
      :key="item.id"
      :to="item.to"
      :active="item.active"
    >
      {{ item.text }}
    </b-breadcrumb-item>
  </k-breadcrumb>
</template>

<script>
import { globalEnv } from "fe-infrastractures";

export default {
  data() {
    return {
      routeName: [],
      globalEnv,
    };
  },
  created: function () {
    this.getBreadcrumbs();
  },
  methods: {
    getBreadcrumbs() {
      this.routeName = [];
      let routeArry = [...this.$route.matched];
      let i = 1;
      if (routeArry[0].meta.isElection) {
        i = 0;
        routeArry = this.handleRoute(routeArry);
      }
      for (i; i < routeArry.length; i++) {
        let breadListArry;
        // eslint-disable-next-line no-prototype-builtins
        let path = routeArry[i].hasOwnProperty("archived")
          ? `${routeArry[i].path}?archived=${routeArry[i].archived}&oneelection=${routeArry[i].oneelection}`
          : routeArry[i].path;

        breadListArry = {
          text: this.$g(`breadcrumb.${routeArry[i].meta.title}`),
          to: path,
        };
        if (i == routeArry.length - 1) {
          breadListArry.active = true;
        }
        this.routeName.push(breadListArry);
      }
    },
    handleRoute(routeAry) {
      let tempObj = {
        meta: {
          title: "electionList",
        },
      };
      if (this.$store.getters.electionIsArchived) {
        Object.assign(tempObj, {
          path: `${this.globalEnv.VUE_APP_BASE_PATH}/dashboard/listArchived`,
          archived: this.$store.getters.electionIsArchived,
          oneelection: "1",
        });
      } else {
        Object.assign(tempObj, {
          path: `${this.globalEnv.VUE_APP_BASE_PATH}/dashboard/listCurrent`,
        });
      }
      let router = {
        meta: {
          title: "",
        },
        path: routeAry[0].path,
      };
      router.meta.title = routeAry[1].meta.title;
      return [tempObj, router];
    },
  },
  computed: {
    electionIsArchived() {
      return this.$store.getters.electionIsArchived;
    },
  },
  watch: {
    electionIsArchived() {
      this.getBreadcrumbs();
    },
  },
};
</script>
<style lang="scss" scoped>
.homeIcon {
  a {
    cursor: auto;

    .b-icon.bi {
      color: var(--breadcrumbIconFont);
      cursor: auto;
    }
  }
}
</style>
