<template>
  <dynamic-nav :navId="PL_NAV_KEY"></dynamic-nav>
</template>

<script>
import { PL_NAV_KEY } from "@/router/permissionCode";
import dynamicNav from './dynamicNav.vue'
export default {
  components: { dynamicNav },
  data () {
    let communicationToggle = this.$store.getters.toggleState['communication']
    if (this.$route.name === 'sendOrganization' ||
        this.$route.name === 'sendFacility'||
        this.$route.name === 'messageDeliveryReport') {
      communicationToggle = true
      this.$store.dispatch('menu/saveNavToggle', {
        type: 'communication',
        value: true,
      })
    }
    return {
      PL_NAV_KEY,
      seenSidebar: false,
      isIconDirection: true,
      communicationToggle: communicationToggle,
    }
  },
  mounted () {
    // if (this.$store.getters.toggleState['communication']) {
    //   this.communicationToggle = true
    // }
  },
  methods: {
    onToggleSidebar () {
      this.seenSidebar = !this.seenSidebar
      this.isIconDirection = !this.isIconDirection
    },
  },
  watch: {
    communicationToggle (val) {
      this.$store.dispatch('menu/saveNavToggle', {
        type: 'communication',
        value: val,
      })
    },
  },
}
</script>
