import { getPortalSetting } from '../../api/app'
import { yesNo } from "fe-infrastractures";

export const portalSettingMixin = {
  data() {
    return {
      isOpen: true,
      portalSwitch: 0,
      portalOffMessage: ''
    }
  },
  mounted() {
    this.checkPortalSetting()
  },
  methods: {
    checkPortalSetting() {
      getPortalSetting().then(res => {
        this.isOpen = res.data.portalSwitch == yesNo.yes
        this.portalSwitch = res.data.portalSwitch
        this.portalOffMessage = res.data.portalOffMessage
      })
    },
  },
};
